import { memo } from "react";

const ImagesHoverSlider = ({ 
    onMouseEnter, 
    APIImage, 
    i, 
    elem
}) => {
    if(elem?.images?.length <= 1) return null;
    return (
        <div className="hover__elements__wrapper">
            {
                elem?.images.length > 1 && elem?.images.map(img => {
                    return  <div onMouseEnter={() => onMouseEnter(`${APIImage}/${img}`, i)} className='hover__element'>
                        <span />
                    </div>
                })
            }
        </div>
    )
};

export default memo(ImagesHoverSlider);
